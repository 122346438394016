import axios from "axios";

export default {
  get: (uid, callback = () => {}) => {
    axios.get(`/api/images/${uid}`).then((response) => {
      callback(response);
    });
  },
  post: (files = [], callback = () => {}) => {
    axios.post("/api/images", files).then((response) => {
      callback(response);
    });
  },
  put: (files = [], id, callback = () => {}) => {
    const param = {
        files,
        id
    }
    axios.put("/api/images", param).then((response) => {
      callback(response);
    });
  }
};
