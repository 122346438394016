import axios from "axios";

export default {
  get: (payload = {}, callback = () => {}) => {
    axios
      .get("/users/rules", payload)
      .then((response) => {
        callback(response.data)
      })
      .catch((err) => console.log(err));
  },
  post: (payload = {}) => {
    axios.post("/users/rules", payload).catch((err) => console.log(err));
  }
};
