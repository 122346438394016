import axios from "axios";

export default {
  post: async (payload = {}) => {
    let audioData = [];
    try {
      const response = await axios("/api/azure/textToSpeech", {
        method: "post",
        responseType: "json",
        data: payload
      });
      audioData = response.data.map((item) => {
        const buffer = new Uint8Array(item.data).buffer;
        const blob = new Blob([buffer], { type: "audio/wav" });
        return { locale: item.locale, blob };
      });
    } catch (error) {
      const errorMessage = `Server error: ${error.message}`;
      throw new Error(errorMessage);
    }
    return audioData;
  }
};
