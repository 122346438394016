// Import the functions you need from the SDKs you need
import { initializeApp, a } from "firebase/app"
// import {
//   getAuth,
//   signInWithPopup,
//   signInWithEmailAndPassword,
//   createUserWithEmailAndPassword,
//   sendPasswordResetEmail,
//   signOut
// } from "firebase/auth"

import {
    GoogleAuthProvider,
    getAuth,
    signInWithPopup,
    signInWithEmailAndPassword,
    createUserWithEmailAndPassword,
    sendPasswordResetEmail,
    updateProfile,
    signOut,
    FacebookAuthProvider,
    GithubAuthProvider,
    sendEmailVerification
  } from "firebase/auth"

  import {
    getFirestore,
    query,
    getDocs,
    collection,
    where,
    addDoc
  } from "firebase/firestore"

  import { User } from "./api/services"

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const app = initializeApp({
  apiKey: "AIzaSyBQV9SvbdpXWDw_p7Xf6v0faa6Qf_9akls",
  authDomain: "digital-assets-352114.firebaseapp.com",
  projectId: "digital-assets-352114",
  storageBucket: "digital-assets-352114.appspot.com",
  messagingSenderId: "308415012822",
  appId: "1:308415012822:web:2d40c02c224a1df26dce77",
  measurementId: "G-TM0VVK1Q8T"
})

// Initialize Firebase
//const app = initializeApp(firebaseConfig)
const auth = getAuth(app)
const db = getFirestore(app)

const googleProvider = new GoogleAuthProvider(app)
const facebookProvider = new FacebookAuthProvider()
const githubProvier = new GithubAuthProvider()

const signInWithGoogle = async () => {
  try {
    const res = await signInWithPopup(getAuth(app), googleProvider)
    const user = res.user
    const q = query(collection(db, "users"), where("uid", "==", user.uid))
    const docs = await getDocs(q)
    if (docs.docs.length === 0) {
      await addDoc(collection(db, "users"), {
        uid: user.uid,
        name: user.displayName,
        authProvider: "google",
        email: user.email
      })
    }
  } catch (err) {
    console.error(err)
    alert(err.message)
  }
}

const signInWithFacebook = async () => {
  console.log("--------------> under facebook popup login")
  const res = await signInWithPopup(getAuth(app), facebookProvider)
}

const signInWithGithub = async () => {
  console.log("--------------> under github popup login")
  const res = await signInWithPopup(getAuth(app), githubProvier)
}

const sendEmailVerificationLink = async (user) => {
  try {
    await sendEmailVerification(user)
  } catch (err) {
    console.log("Error while sending verification link: ", err.message)
    throw new Error(err.message)
  }
}

const updateUserInfo = async (displayName, photoURL) => {
  try {
    await updateProfile(authentication.currentUser, {
      displayName,
      photoURL
    })
  } catch (err) {
    console.log("error", JSON.stringify(err))
    throw new Error(err.code)
  }
}

const logInWithEmailAndPassword = async (email, password,  callback = () => {}) => {
    try {
      await signInWithEmailAndPassword(auth, email, password)
    } catch (err) {
      console.error(err)
      callback(err, 'User not found')
    }
  }

  const registerWithEmailAndPassword = async (displayName, email, password) => {
    try {
      const res = await createUserWithEmailAndPassword(auth, email, password)
      const user = res.user
      const params = {
        uid: user.uid,
        displayName,
        email,
        password,
        emailVerified: user.emailVerified,
        photoUrl: user.photoURL,
        phoneNo: user.phoneNumber,
        provider: res.providerId,
        metadata:{
          createdAt: user.metadata.createdAt,
          lastLoginAt: user.metadata.lastLoginAt,
          lastSignInTime: user.metadata.lastSignInTime,
          creationTime: user.metadata.creationTime
        }
      }
      User.post(params)
      await sendEmailVerificationLink(res.user)
    } catch (err) {
      console.error(err)
      alert(err.message)
      throw new Error(`Error in user registration: ${err.message}`)
    }
  }

  const sendPasswordReset = async (email) => {
    try {
      await sendPasswordResetEmail(getAuth(app), email)
      alert("Password reset link sent!")
    } catch (err) {
      console.error(err)
      alert(err.message)
    }
  }

  const logout = () => {
    signOut(auth)
  }

  export {
    auth,
    db,
    signInWithGoogle,
    signInWithFacebook,
    signInWithGithub,
    sendEmailVerificationLink,
    logInWithEmailAndPassword,
    registerWithEmailAndPassword,
    sendPasswordReset,
    signInWithEmailAndPassword,
    updateUserInfo,
    logout
  }