import axios from "axios"

export default {
    get: async (type, obj,  callback = async () => {}) => {
        const params = obj
        await axios.get(`/image/${type}`, {params}).then(async (response) => {
            await callback(response)
        }).catch(err => console.log(err))
    },
    convertToJpeg: async(obj, callback = async () => {}) => {
        const params = obj
        await axios.get("/image/toJpeg", {params}).then(async (response) => {
            await callback(response)
        }).catch(err => console.log(err))
    }
}
