import axios from "axios"

const baseURL = `${process.env.REACT_APP_ENDPOINT_BASE}users/folders`;
const error = (op, msg) => `HTTP ${op} Error in Image Collections! ${msg}`;

export default {
  get: (parentFolderId = 0, callback = () => {}) => {
    axios.get(baseURL, { params: { parentFolderId }})
      .then(({ data }) => {
        data.sort((a, b) => (a.folderName < b.folderName ? -1 : 1));
        callback(data); 
      })
      .catch((err) => console.log(error('GET', err.message)));
  },
  create: (folderName = '', parentFolderId = 0, callback = () => {}) => {
    axios.post(baseURL, {
      data: { folderName, parentFolderId, action: 'create' }
    })
      .then((res) => callback(res))
      .catch((err) => console.log(error('POST', err.message)));
  },
  action: (
    action = 'copy',
    folderIds = [],
    parentFolderId = 0, 
    callback = () => {}
  ) => {
    axios.post(baseURL, {
      data: { folderIds, parentFolderId, action }
    })
      .then((res) => callback(res))
      .catch((err) => console.log((error('POST'), err.message)));
  },
  delete: (folderIds = [], callback = () => {}) => {
    axios.delete(baseURL, { data: { folderIds } })
      .then((res) => callback(res))
      .catch((err) => console.log((error('DELETE', err.message))));
  }
}