import axios from "axios"

export default {
    post: async (payload = {}) => {
        let data = null
        try {
            const response = await axios.post("/api/openai/completion", payload)
            data = await response.data

            if (data.blog && data.blog.includes("The prompt has raised these moderation flags")) {
                console.log("Moderation flags raised. Error: ", data.blog)
                throw new Error("Please avoid using explicit content in your prompt.")
            }
        } catch (error) {
            throw new Error(`Error while fetching data: ${error.message}`)
        }
        return data.blog
    }
}
