import axios from "axios";

export default {
  postUnsplash: (payload = {}, callback = () => {}) => {
    axios.post("/api/search/unsplash", payload).then((response) => {
        callback(response.data)
    })
  },
  postPexels: (payload = {}, callback = () => {}) => {
    axios.post("/api/search/pexels", payload).then((response) => {
        callback(response.data)
    })
  }
};
