import React, { useEffect, useMemo, useState } from "react"
import { getAuth, onAuthStateChanged } from "firebase/auth"

const UserContext = React.createContext()

export const UserProvider = ({ children }) => {
    const auth = getAuth()
    const [user, setUser] = useState({})
    const value = useMemo(
        () => ({
            auth,
            user,
            setUser
        }),
        [user]
    )

    useEffect(() => {
        onAuthStateChanged(auth, (user) => {
            setUser({
                ...user
            })
        })
    }, [])

    return <UserContext.Provider value={value}>{children}</UserContext.Provider>
}

export const useUser = () => {
    const context = React.useContext(UserContext)

    if (!context) {
        throw new Error("useUser must be inside of UserContext Provider")
    }

    return context
}
