import axios from "axios"


export default {
    get: async (id, imageId) => {
        return await axios.get(`/users/likes/${id}/${imageId}`)
    },
    post: async(payload = {}) => {
        return await axios.post(`/users/likes`, payload)
    }
}
