import axios from "axios"

/** Checks if the prompt is valid. The prompt is valid if its length is between the min and max char length.
 * @param {String} prompt prompt string
 * @param {Number} minCharLength minimum character length
 * @param {Number} maxCharLength maximum character length
 */
export const isValidPrompt = (prompt, minCharLength, maxCharLength) => {
  return (
    prompt.length >= minCharLength &&
    prompt.length <= maxCharLength
  )
}

/** Checks if the prompt entered is invalid. The prompt is invalid if its length is not between the min and max char length.
 * The prompt input field uses this boolean to highlight the input field when the entered prompt length is invalid.
 * @param {String} prompt prompt string
 * @param {Number} minCharLength minimum character length
 * @param {Number} maxCharLength maximum character length
 */
export const showInvalidPrompt = (prompt, minCharLength, maxCharLength) => {
  return (
    (prompt.length >= 1 &&
    prompt.length < minCharLength) ||
    prompt.length > maxCharLength
  )
}

/** Checks if the image is a square given the allowed extensions and file size limit.
 * @param {Object} imageMetadata image metadata
 * @param {Array}  allowedExtensions an array of allowed extensions (e.g. ["png", "jpeg"])
 * @param {Number} maxSize maximum image file size limit
 */
export const isValidImageFormat = (imageMetadata, allowedExtensions, maxSize) => {
  return (
    allowedExtensions.includes(imageMetadata.format) &&
    imageMetadata.size < maxSize &&
    imageMetadata.height === imageMetadata.width
  )
}

/** Checks if the mask image is a square Checks if the image is a square given the
 * allowed extensions and file size limit, and has the same dimension as the image.
 * @param {Object} maskMetadata mask image metadata
 * @param {Object} imageMetadata image to edit metadata
 * @param {Array}  allowedExtensions an array of allowed extensions (e.g. ["png", "jpg"])
 * @param {Number} maxSize maximum image file size limit
 */
export const isValidMaskImageFormat = (maskMetadata, imageMetadata, allowedExtensions, maxSize) => {
  return (
    isValidImageFormat(maskMetadata, allowedExtensions, maxSize) &&
    maskMetadata.height === imageMetadata.height &&
    maskMetadata.width === imageMetadata.width
  )
}

/** Gets an array of image objects with key and source from data returned by Text to Image api.
 * @param {Object} data Text to Image api response data
 */
const getImages = (data) => {
  let images = []
  if (!Array.isArray(data.blog.imageGen)) {
    if (data.blog && data.blog.includes("moderation flag")) {
      console.log("Moderation flags raised. Error: ", data.blog.data)
      throw new Error("Please avoid using explicit content in your prompt.")
    }
    throw new Error(`Error while fetching image data. ${data.blog.imageGen}`)
  } else {
    images = data.blog.imageGen.map((item, index) => ({
      source: item,
      key: index,
      GCS: data.blog.GCS[index] 
    }))
  }
  return images
}

/** Sends a post request for OpenAI image generations to the given url &
 *  gets an array of image data.
 * @param {string} url OpenAI image generation url
 * @param {payload} payload post request payload
 */
export const sendPostRequest = async (url, payload = {}) => {
  let data = null
  payload.NumGen = parseInt(payload.NumGen, 10)
  try {
    const response = await axios(url, {
      method: "post",
      headers: {
        "Content-Type": "application/json"
      },
      data: JSON.stringify(payload)
    })
    data = await response.data
  } catch (error) {
    const errorMessage = `Server error: ${error.message}`
    throw new Error(errorMessage)
  }
  return getImages(data)
}

/** Scroll to generated images component with the given component id.
 * @param {string} id component id
*/
export const scrollToOutput = (id = "generated-data") => {
    const section = document.querySelector(`#${id}`)
    window.scrollTo(0, section.offsetTop)
}
