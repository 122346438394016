import axios from "axios"

export default {
    get: (id, callback = () => {}) => {
        axios.get(`/api/image/${id}`).then((response) => {
            callback(response)
        })
    },
    post: (file = {}, callback = () => {}) => {
        axios.post("/api/image", file).then((response) => {
            callback(response)
        })
    }
}
