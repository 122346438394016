import axios from "axios"

export default {
    get: async (id) => {
        return await axios.get(`/users/register/${id}`)
    },
    post: (file = {}, callback = () => {}) => {
        axios.post("/users/register", file).then((response) => {
            callback(response)
        })
    },
    put: (file = {}, callback = () => {}) => {
        axios.put("/users/register", file).then((response) => {
            callback(response)
        })
    }
}