import axios from "axios"

export default {
    get: (id, callback = () => {}) => {
        axios.get(`/users/setting/googleVision/${id}`).then((response) => {
            callback(response)
        })
    },
    post: (files = {}, callback = () => {}) => {
        axios.post("/users/setting/googleVision", files).then((response) => {
            callback(response)
        })
    }
}